import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { StyledInformationalPage } from '../../common/static.styles';

export const StyledInternationalPage = styled(StyledInformationalPage)`
  .flag {
    &::before {
      background-image: url('/assets/flag-icons.png');
      background-repeat: no-repeat;
    }

    &.be::before {
      background-position: 0 -60px;
    }

    &.br::before {
      background-position: 0 -120px;
    }

    &.ca::before {
      background-position: 0 -140px;
    }

    &.ch::before {
      background-position: 0 -160px;
    }

    &.de::before {
      background-position: 0 -300px;
    }

    &.es::before {
      background-position: 0 -380px;
    }

    &.fr::before {
      background-position: 0 -420px;
    }

    &.uk::before {
      background-position: 0 -440px;
    }

    &.in::before {
      background-position: 0 -560px;
    }

    &.it::before {
      background-position: 0 -620px;
    }

    &.jp::before {
      background-position: 0 -640px;
    }

    &.mx::before {
      background-position: 0 -760px;
    }

    &.nl::before {
      background-position: 0 -800px;
    }

    &.at::before {
      background-position: 0 -20px;
    }

    &.pl::before {
      background-position: 0 -920px;
    }

    &.pt::before {
      background-position: 0 -960px;
    }

    &.tr::before {
      background-position: 0 -1160px;
    }

    &.us::before {
      background-position: 0 -1180px;
    }
  }

  ul {
    margin-bottom: 20px;
    column-count: 3;

    ${mediaQueryWidth({ max: 'desktop' })`
      column-count: 2;
    `}

    ${mediaQueryWidth({ max: 'mobile' })`
      column-count: 1;
    `}

    li {
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;

      &::before {
        border: solid 1px #999999;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: 5px;
        position: relative;
        width: 30px;
        top: 4px;
      }
    }
  }
`;
