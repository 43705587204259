import * as React from 'react';
import { useRouteData } from 'react-static';
import { useDebug } from '../../common/hooks/useDebug';
import { useTranslations } from '../../common/translation';
import { FeaturedBanner } from '../../components/featured-banner/FeaturedBanner';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { DefaultLayout } from '../default/DefaultLayout';
import { LocaleProps } from '../default/DefaultLayout.props';
import { StyledInternationalPage } from './InternationalLayout.styles';
import translations from './translations';

// noinspection JSUnusedGlobalSymbols
export default () => {
  const { locale, translatedPaths } = useRouteData<LocaleProps>();
  const { t } = useTranslations(translations, locale);
  const seo = {
    description: '',
  };
  const title = t('sectionTitle', 'International Sites');
  const bannerTitle = { title };
  const imageWithPresets: ImageWithPresets = {
    image: {
      alt: title,
      src: '/assets/international/big-bang-theory-flags.png',
    },
    tabletImage: {
      alt: title,
      src: '/assets/international/big-bang-theory-flags-tablet.png',
    },
    mobileImage: {
      alt: title,
      src: '/assets/international/big-bang-theory-flags-mobile.png',
    },
  };

  const regions: Array<{ name: string; code: string; url: string }> = [
    {
      code: 'be',
      url: 'https://www.warnerbros.be',
      name: 'Belgium',
    },
    {
      code: 'br',
      url: 'https://www.warnerbros.com.br',
      name: 'Brazil',
    },
    {
      code: 'ca',
      url: 'https://www.warnerbroscanada.com',
      name: 'Canada',
    },
    {
      code: 'de',
      url: 'https://www.warnerbros.de',
      name: 'Deutschland',
    },
    {
      code: 'es',
      url: 'https://www.warnerbros.es',
      name: 'España',
    },
    {
      code: 'fr',
      url: 'https://www.warnerbros.fr',
      name: 'France',
    },
    {
      code: 'in',
      url: 'https://www.warnerbros-india.com',
      name: 'India',
    },
    {
      code: 'it',
      url: 'https://www.warnerbros.it',
      name: 'Italia',
    },
    {
      code: 'mx',
      url: 'http://www.warnerbroslatino.com',
      name: 'México | Latinoamérica',
    },
    {
      code: 'nl',
      url: 'https://www.warnerbros.nl',
      name: 'Nederland',
    },
    {
      code: 'at',
      url: 'https://www.warnerbros.at',
      name: 'Österreich',
    },
    {
      code: 'pl',
      url: 'http://www.warnerbros.pl',
      name: 'Polska',
    },
    {
      code: 'pt',
      url: 'http://pt.warnerbros.com',
      name: 'Portugal',
    },
    {
      code: 'ch',
      url: 'http://www.warnerbros.ch',
      name: 'Schweiz',
    },
    {
      code: 'tr',
      url: 'http://www.warnerbros.com.tr',
      name: 'Türkiye',
    },
    {
      code: 'uk',
      url: 'https://www.warnerbros.co.uk',
      name: 'United Kingdom',
    },
    {
      code: 'us',
      url: 'https://www.warnerbros.com',
      name: 'United States',
    },
    {
      code: 'jp',
      url: 'https://www.warnerbros.co.jp',
      name: '日本',
    },
  ];

  useDebug('app:layout:InternationalLayout', { props: { locale } });

  return (
    <DefaultLayout locale={locale} seo={seo} translatedPaths={translatedPaths}>
      <HeadTitle title={t('pageTitle', 'International Sites')} />
      <StyledInternationalPage>
        <FeaturedBanner headingTag='h1' imageWithPresets={imageWithPresets} titleProps={bannerTitle} />
        <div className='content'>
          <ul>
            {regions.map((region) => (
              <li className={`flag ${region.code}`} key={region.code}>
                <a href={region.url} target='_blank'>
                  {region.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </StyledInternationalPage>
    </DefaultLayout>
  );
};
